import { NgModule } from "@angular/core";
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
//import { MatButtonModule } from "@angular/material/button";

@NgModule({
  declarations: [],
  imports: [
    //MatButtonModule,
  ],
  exports: [
    // MatButtonModule,
  ],
})
export class AngularMaterialModule {}

//This is Sample Angular Material Module. with all common Material dependencies
