import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class SharedManagerService {
  
  // This Service will contain All Coommon services for shared and any Observables between child & parent

  constructor() {}
}
