import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";

import { AngularMaterialModule } from "./angular-material.module";
import { PowerPipe } from "./pipes/power.pipe";
import { SharedManagerService } from "./shared-manager.service";
import { LoadingOverlayComponent } from "./components/loading-overlay/loading-overlay.component";

@NgModule({
  declarations: [
    PowerPipe,
    LoadingOverlayComponent
  ],
  imports: [
    CommonModule,
    MatProgressSpinnerModule
  ],
  exports: [CommonModule, AngularMaterialModule, PowerPipe],
})
export class SharedModule {
  static forRoot() {
    return {
      ngModule: SharedModule,
      providers: [SharedManagerService],
    };
  }
}
