import { Injectable } from "@angular/core";
import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse,
  HttpParams,
} from "@angular/common/http";
import { Observable, BehaviorSubject } from "rxjs";
//import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { OktaAuthService } from "@okta/okta-angular";

import { environment } from "../../../environments/environment";


@Injectable({
  providedIn: "root",
})
export class HttpUtility {
  constructor(
    private http: HttpClient,
    //private dialog: MatDialog,
    private router: Router,
    public oktaAuth: OktaAuthService
  ) {}

  private showSpinnerObservable = new BehaviorSubject<boolean>(true);
  public readonly showSpinner = this.showSpinnerObservable.asObservable();
  private baseUri = environment.baseUri;

  public async Post<T>(
    path: string,
    request: any,
    errorMsg?: string
  ): Promise<T> {
    const options = await this.HttpOptions();

    return await this.doCall<T>(
      this.http.post(`${this.baseUri}${path}`, request, options),
      errorMsg
    );
  }

  public async Delete<T>(
    path: string,
    request: any,
    errorMsg?: string
  ): Promise<T> {
    return await this.doCall<T>(
      this.http.delete(`${this.baseUri}${path}`, request),
      errorMsg
    );
  }

  public async Get<T>(path: string, errorMsg?: string, params?: Map<string, string>): Promise<T> {
    const uri = this.baseUri + path;
    let httpOptions = await this.HttpOptions(params);

    const response = this.doCall<T>(
      this.http.get(`${uri}`, httpOptions),
      errorMsg
    );
    return response;
  }

  public async getBlob(path: string, errorMsg?: string, params?: Map<string, string>): Promise<Blob> {
    const headers = await this.httpHeaders();
    const httpParams = this.httpParams(params);

    const response = this.doCall<Blob>(
      this.http.get(`${this.baseUri}${path}`, {headers: headers, params: httpParams, responseType: 'blob'}),
      errorMsg
    )

    return response
  }

  public async postFile(
    path: string,
    file: File,
    fileparam: string,
    body?: Map<string, string>,
    errorMsg?: string): Promise<any> {
      //we get rid of content type, since FormData handles this
      const headers = (await this.httpHeaders()).delete("Content-Type")

      const formData = new FormData()
      formData.append(fileparam, file, file.name)

      body?.forEach((value: string, key: string) => {
        formData.append(key, value)
      })

      return this.doCall<any>(
        this.http.post(`${this.baseUri}${path}`, formData, {headers: headers}),
        errorMsg
      )
    }

  private async doCall<T>(
    observable: Observable<object>,
    errorMsg?: string
  ): Promise<T> {
    const promise = observable.toPromise();
    let response: T;
    try {
      this.presentSpinner();
      const serverResponse = ((await promise) as unknown) as T;
      response = serverResponse;
      this.dismissSpinner();
    } catch (error) {
      this.dismissSpinner();
      console.log(error.message)
      if (error instanceof HttpErrorResponse) {
        this.handleHTTPError(error);
      } else {
        this.handleError(error.message);
      }
    }
    return response;
  }

  private handleHTTPError(error: HttpErrorResponse) {
    let message = error.message;
    if (typeof error.status === 'number') {
      switch (error.status) {
        case 409: {
          message = "Event is locked by another agent";
          this.router.navigate(["/"]);
        }
        case 403: {
          this.router.navigate(["/unauthorized"]);
          break;
        }
        default: {
          this.handleError(message);
          break;
        }
      }
    }
  }

  private async HttpOptions(params?: Map<string, string>): Promise<{ params: HttpParams, headers: HttpHeaders }> {

    const httpParams = await this.httpParams(params)
    const headers = await this.httpHeaders()

    return {
      params: httpParams,
      headers: headers
    };
  }

  private async httpHeaders(): Promise<HttpHeaders> {
    const token = await this.oktaAuth.getAccessToken();

    return new HttpHeaders({
      Accept: "application/*",
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept",
        "Content-Type": "application/*",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Credentials": "true",
    })
  }

  private httpParams(params?: Map<string, string>): HttpParams {
    var httpParams = new HttpParams();
    params?.forEach((value: any, key: string) => {
      httpParams = httpParams.append(key, value)
    })
    return httpParams
  }

  private handleError(message: string) {
    // this.dialog.open(ErrorDialogComponent, {
    //   data: {
    //     message: message,
    //   },
    // });
  }

  private presentSpinner() {
    this.showSpinnerObservable.next(true);
  }

  private dismissSpinner() {
    this.showSpinnerObservable.next(false);
  }
}
