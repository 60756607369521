import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { MatDialogRef } from "@angular/material/dialog";
import { DomSanitizer } from "@angular/platform-browser";
import { UserInfo } from "src/app/contracts/models/home/response/user-info-response.model";
import { UserService } from "src/app/services/user.service";

@Component({
  selector: "app-manage-inspectors",
  templateUrl: "./manage-inspectors.component.html",
  styleUrls: ["./manage-inspectors.component.scss"],
})
export class ManageInspectorsComponent implements OnInit {
  inspectorSearchForm = new FormGroup({
    employeeID: new FormControl("", [Validators.required]),
  });

  userInformation: UserInfo[] = [];

  fileUrl = null;
  uploadedFile: File = null;

  showUserError = false;

  didSearch: boolean = false;
  addingFile: boolean = false;
  showSave: boolean = false;
  showAddFileButton: boolean = false;
  showFileUploadError: boolean = false;
  fileDropCssClass: string = 'file-drop-area';

  filePlaceholder: string = "No file available"
  fileAddText: string = ".PDF Required"

  constructor(
    public dialogRef: MatDialogRef<ManageInspectorsComponent>,
    private userService: UserService,
    private domSanitizer: DomSanitizer
  ) {}

  ngOnInit() {
    this.resetVisualState();
  }

  searchInspector() {
    if (this.inspectorSearchForm.valid) {
      this.resetVisualState();
      this.userService.retrieveUserInfo(this.inspectorSearchForm.value.employeeID).then((userInfo) => {
          if (userInfo) {
            this.userInformation = userInfo.users;
            this.getInspectorForm()
          } else {
            this.showUserError = true
          }
        });
    }
  }

  getInspectorForm() {
    this.userService.retrieveInspectionForm(this.inspectorSearchForm.value.employeeID).then((file) => {
      this.didSearch = true;
      this.showAddFileButton = true;
      if (file) {
        this.fileUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(
          URL.createObjectURL(file)
        );
      }
    });
  }

  beginAddingFile() {
    this.addingFile = true;
    this.showAddFileButton = false;
  }

  saveForm() {
    this.getUserRole().then((role) => {
      this.userService
      .postInspectionForm(this.userInformation[0], this.uploadedFile, role ?? "")
      .then((value) => {
        this.showSave = false;
        this.addingFile = false;
        this.fileUrl = null
        this.filePlaceholder = this.uploadedFile.name;
        document.getElementById("closeButton").innerText = "CLOSE";
      });
    })
  }

  async getUserRole() {
    let userRole = await this.userService.retrieveUserRoles(this.userInformation[0].employeeNumber)
    return userRole.roles[0]
  }

  onCloseClick() {
    this.dialogRef.close();
  }

  fileBrowserHandler(files: FileList) {
    this.uploadedFile = files.item(0);
    this.validateFileType();
  }

  storeFile(event: File) {
    this.uploadedFile = event;
    this.resetInputFileName();
    this.validateFileType();
  }

  inputChanged(event) {
    let input: string = event.target.value
    if (input.length === 0) {
      this.showUserError = false
    }
  }

  resetVisualState() {
    this.didSearch = false;
    this.addingFile = false;
    this.showAddFileButton = false;
    this.showSave = false;
    this.showUserError = false
    this.showFileUploadError = false;
  }

  getFileDropCssClass(): string {
    return this.fileDropCssClass;
  }

  // to empty out the file name after selecting a file vs drag&drop
  private resetInputFileName() {
    var inputs = document.getElementsByTagName('input');
    for (var i = 0; i < inputs.length; i++) {
      if (inputs[i].type.toLowerCase() == 'file') {
        inputs[i].value = '';
      }
    }
  }

  private validateFileType() {
    const fileType: string = "application/pdf";
    if (this.uploadedFile !== null && this.uploadedFile.type !== fileType) {
      this.showFileUploadError = true;
      this.showSave = false;
      this.fileDropCssClass = 'file-drop-area-error';
    } else {
      this.showFileUploadError = false;
      this.showSave = true;
      this.fileDropCssClass = 'file-drop-area';
    }
  }

}
