import { Component, OnDestroy, OnInit } from "@angular/core";
import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import { Observable, Subscription } from "rxjs";
import { map, shareReplay } from "rxjs/operators";
import { OktaAuthService } from "@okta/okta-angular";
import { UserService } from "src/app/services/user.service";
import { MatIconRegistry } from "@angular/material/icon";
import { DomSanitizer } from "@angular/platform-browser";
import { MatDialog } from "@angular/material/dialog";

import { ManageInspectorsComponent } from "../manage-inspectors/manage-inspectors.component";

@Component({
  selector: "app-main-nav",
  templateUrl: "./main-nav.component.html",
  styleUrls: ["./main-nav.component.scss"],
})
export class MainNavComponent implements OnInit, OnDestroy {
  isAuthenticated: boolean;
  userName = "Not Logged In";
  hasIcon: boolean = false;

  userRoles: string[] = []

  private authSubscription: Subscription

  isHandset$: Observable<boolean> = this.breakpointObserver
    .observe(Breakpoints.Handset)
    .pipe(
      map((result) => result.matches),
      shareReplay()
    );

  isWeb$: Observable<boolean> = this.breakpointObserver
    .observe(Breakpoints.WebLandscape)
    .pipe(
      map((result) => result.matches),
      shareReplay()
    );

  constructor(
    private breakpointObserver: BreakpointObserver,
    private oktaAuth: OktaAuthService,
    private userService: UserService,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    public dialog: MatDialog
  ) {}

  async ngOnInit() {
    this.isAuthenticated = await this.oktaAuth.isAuthenticated();
    this.updateUser();
    this.registerIcons();

    this.authSubscription = this.oktaAuth.$authenticationState.subscribe((isAuthenticated) => {
      this.isAuthenticated = isAuthenticated;
      console.log(
        `NAV BAR - updating user authenticated is ${isAuthenticated}`
      );
      this.updateUser();
    });
  }

  ngOnDestroy() {
    this.authSubscription.unsubscribe();
  }

  logout() {
    console.log("Logout called from nav bar.");
    this.oktaAuth.signOut();
  }

  async updateUser() {
    if (!this.isAuthenticated) {
      this.userName = "Not Logged In";
    } else {
      let user = await this.oktaAuth.getUser();
      this.userName = `${user.given_name} ${user.family_name}`;

      if(user.employeeNumber) {
        this.userService.retrieveUserRoles(user.employeeNumber).then((userRoles) => {
          if (userRoles.roles) {
            this.userRoles = userRoles.roles
          }
        })
      }
      
    }
  }

  openManageInspectors() {
    this.dialog.open(ManageInspectorsComponent, {
      width: '525px'
    })
  }

  registerIcons() {
    this.matIconRegistry.addSvgIcon(
      "manageUsers",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../../../../../assets/icon/users.svg")
    )
    this.hasIcon = true
  }

  isManager(): boolean {
    return this.userRoles.includes('MANAGER')
  }

}
