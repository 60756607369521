export enum ApiPaths {
    SearchInspections = "/Equipment/inspections",
    Inspection = "/Equipment/inspection",
    AdministrationRoles = "/Administration/roles",
    AdministrationUserRoles = "/Administration/userRoles",
    AdministrationForm = "/Administration/inspectorForm",
    AdministrationUsers = "/Administration/users"
}

export namespace ApiPaths {
    export function InspectionImage(inspectionId: string, inspectionImageId: string): string {
        return `${ApiPaths.Inspection}/${inspectionId}/images/${inspectionImageId}`
    }

    export function UserRole(userID: string): string {
        return `${ApiPaths.AdministrationRoles}/${userID}`
    }

    export function InspectorForm(inspectorID: string): string {
        return `${ApiPaths.AdministrationForm}/${inspectorID}`
    }
}