import { EventEmitter, Injectable, Output } from '@angular/core';

import { ApiPaths } from "../../app/contracts/enums/api-paths";
import { UserInfo, UserInfoResponse } from '../contracts/models/home/response/user-info-response.model';
import { UserRolesResponse } from '../contracts/models/home/response/user-roles-reponse.model';
import { HttpUtility } from '../utilities/network/http.utility';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private http: HttpUtility) { }

  retrieveUserInfo(userEmployeeNumber: string): Promise<UserInfoResponse> {
    console.log("Retrieving user information")
    let params = new Map<string, string>()
    params.set("employeeNumber", userEmployeeNumber)

    return this.http.Get<UserInfoResponse>(ApiPaths.AdministrationUsers, "Could not retrieve user", params)
  }

  retrieveUserRoles(userEmployeeNumber: string): Promise<UserRolesResponse> {
    console.log("Retrieving user roles")

    return this.http.Get<UserRolesResponse>(ApiPaths.UserRole(userEmployeeNumber))
  }

  retrieveInspectionForm(inspectorEmployeeNumber: string): Promise<Blob> {
    console.log("Retrieving Inspector Qualification Form")
    return this.http.getBlob(ApiPaths.InspectorForm(inspectorEmployeeNumber))
  }

  postInspectionForm(inspector: UserInfo, file: File, userRole: string): Promise<any> {

    console.log("Uploading file")
    let params = new Map<string, string>()
    params.set("EmployeeNumber", inspector.employeeNumber ?? "")
    params.set("FirstName", inspector.firstName ?? "")
    params.set("LastName", inspector.lastName ?? "")
    params.set("Email", inspector.email ?? "")
    params.set("RoleName", userRole)
    
    return this.http.postFile(ApiPaths.AdministrationUserRoles, file, "inspector form", params)
  }
}
