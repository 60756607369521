import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { finalize, tap } from "rxjs/operators";
import { LoadingOverlayService } from "src/app/services/loading-overlay.service";

import { environment } from "../../../environments/environment";

@Injectable({
    providedIn: "root",
  })
export class ApiInterceptor implements HttpInterceptor {

    constructor(private overlayService: LoadingOverlayService) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        //show the loading overlay when making http requests
        this.overlayService.show()

        //Use this to modify a new request that is cloned, rather than modify original
        const request = req.clone({
            
        })

        //Log the request if you're not in production
        if (!environment.production) {
            this.logRequest(request)   
        }

        return next.handle(request).pipe(
            tap((event: HttpEvent<any>) => {
                //If not in prod, log the response
                if (!environment.production) {
                    this.logResponse(event)
                }
            })
        ).pipe(
            finalize(() => {
                //finally, hide the loading overlay
                this.overlayService.hide()
            })
        )
    }

    private logRequest(req: HttpRequest<any>) {
        console.log("REQUEST:")
        console.log(req)
    }

    private logResponse(httpEvent: HttpEvent<any>) {
        console.log("RESPONSE:")
        console.log(httpEvent)
    }

}