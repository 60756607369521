import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { LoadingOverlayComponent } from '../modules/shared/components/loading-overlay/loading-overlay.component';

//Service used to manage when we show a loading overlay
//We do it this way to have it all in one place instead of in the home component and other components
@Injectable({
  providedIn: 'root'
})
export class LoadingOverlayService {

  private _overlayRef: OverlayRef

  //A semaphore to keep us from showing multiple overlays on top of one another
  private _overlaySemaphore: number = 0

  constructor(private overlay: Overlay) { }

  show() {
    //Only show an overlay if you're not already showing one
    if (this._overlaySemaphore == 0) {
      this._overlayRef = this.overlay.create({
        positionStrategy: this.overlay.position().global().centerHorizontally().centerVertically(),
        hasBackdrop: true
      })
      this._overlayRef.attach(new ComponentPortal(LoadingOverlayComponent)) 
    }
    //increment the semaphore so we know how many times we've tried to show this
    this._overlaySemaphore += 1
  }

  hide() {
    //decrease semaphore, so that we know we have one less ooccurence of showing the overlay
    this._overlaySemaphore -= 1

    //Only actually detach and null when no more occurences of showing the overlay are happening
    if (this._overlaySemaphore == 0) {
      this._overlayRef.detach()
      this._overlayRef = null 
    }
  }
}
